document.addEventListener('DOMContentLoaded', () => {

const currentDate = document.querySelector('.current-date'),
daysTag = document.querySelector('.days'),
prevNextIcon = document.querySelectorAll('.datepicker__head span');



let date = new Date(),
currYear = date.getFullYear(),
currMonth = date.getMonth();


const months = ["Январь", "Февраль","Март","Апрель",
				"Май","Июнь","Июль","Август","Сентябрь",
				"Октябрь","Ноябрь","Декабрь"

				]

const renderCalendar = () => {
	let firstDayofMonth = new Date(currYear,currMonth, 0).getDay(),
	lastDateofMonth = new Date(currYear,currMonth + 1, 0).getDate(),
  	lastDayoftMonth = new Date(currYear,currMonth, lastDateofMonth).getDay(),
	lastDateofLastMonth = new Date(currYear,currMonth, 0).getDate();
	let liTag = '';
	console.log(firstDayofMonth)
	for (let i = firstDayofMonth; i > 0; i--) {
		liTag += `<li class="inactive">${lastDateofLastMonth - i + 1}</li>`;
	}

	for (let i = 1; i <= lastDateofMonth; i++){
		let isToday = i === date.getDate() && currMonth === new Date().getMonth()
					&& currYear === new Date().getFullYear() ? "active" : "";
		liTag += `<li class="${isToday}">${i}</li>`;
	}

	for (let i = lastDayoftMonth; i < 7; i++) {
		liTag += `<li class="inactive">${i - lastDayoftMonth + 1}</li>`;
	}

	if(currentDate){
	currentDate.innerHTML = `${months[currMonth]}`;
	daysTag.innerHTML = liTag;
	}
}

renderCalendar();

prevNextIcon.forEach(icon => {
	icon.addEventListener('click', () =>{
		currMonth = icon.id === "prev" ? currMonth - 1 : currMonth + 1;
		
		if(currMonth < 0 || currMonth > 11 ) {
			date = new Date(currYear,currMonth);
			currYear = date.getFullYear();
			currMonth = date.getMonth();
		} else {
			date = new Date();
		}
		renderCalendar();
	})
})

})