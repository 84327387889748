console.info('main js');

//
import './burger-menu/burger-menu.js'
import './dragslide/dragslide.js'
import './dropdown/dropdown.js'
import './datepick/calendar.js'

// modules
import { LayoutPagePreloader } from './modules/layout/page-preloader.js';


// init
document.addEventListener('DOMContentLoaded', () => {

	// layouts
	LayoutPagePreloader.init();
	
});