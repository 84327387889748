const dd = document.querySelector('.top-users__dropdown');
const links = document.querySelectorAll('.top-users__list a');
const btn = document.querySelector('.top-users__choose');

if (dd) {
	dd.addEventListener('click', function() {
		this.classList.toggle('is-active');
  });


  links.forEach((element) => {
	element.addEventListener('click', function(evt) { 
		btn.innerHTML = evt.currentTarget.textContent;
	})
  })
}


