(function () {
	'use strict';

	document.addEventListener('DOMContentLoaded', () => {
		const hamburger = document.querySelector(".hamburger");
		const navMenu = document.querySelector(".nav-menu");
		let bodyscroll = document.querySelector('body');

		hamburger.addEventListener("click", () => {
			hamburger.classList.toggle("active");
			navMenu.classList.toggle("active");
			bodyscroll.classList.toggle("scroll-off");
		  });

		  document.querySelectorAll(".nav-link").forEach((link) =>
		  link.addEventListener("click", () => {
			hamburger.classList.remove("active");
			navMenu.classList.remove("active");
		  })
		);

	});

	const slider = document.querySelectorAll('.items');
	    let isDown = false;
	    let startX;
	    let scrollLeft;

	    slider.forEach((slide) =>{
	        slide.addEventListener('mousemove', (e) =>{
	            if (!isDown) return;
	            e.preventDefault();
	            const x = e.pageX - slide.offsetLeft;
	            const walk = (x - startX) * 3; //scroll-fast
	            slide.scrollLeft = scrollLeft - walk;

	        });
	        slide.addEventListener('mousedown', (e) => {
	            isDown = true;
	            slide.classList.add('active');
	            startX = e.pageX - slide.offsetLeft;
	            scrollLeft = slide.scrollLeft;
	        });
	        slide.addEventListener('mouseleave', () => {
	            isDown = false;
	            slide.classList.remove('active');
	        });
	        slide.addEventListener('mouseup', () => {
	            isDown = false;
	            slide.classList.remove('active');
	        });

	    });

	const dd = document.querySelector('.top-users__dropdown');
	const links = document.querySelectorAll('.top-users__list a');
	const btn = document.querySelector('.top-users__choose');

	if (dd) {
		dd.addEventListener('click', function() {
			this.classList.toggle('is-active');
	  });


	  links.forEach((element) => {
		element.addEventListener('click', function(evt) { 
			btn.innerHTML = evt.currentTarget.textContent;
		});
	  });
	}

	document.addEventListener('DOMContentLoaded', () => {

	const currentDate = document.querySelector('.current-date'),
	daysTag = document.querySelector('.days'),
	prevNextIcon = document.querySelectorAll('.datepicker__head span');



	let date = new Date(),
	currYear = date.getFullYear(),
	currMonth = date.getMonth();


	const months = ["Январь", "Февраль","Март","Апрель",
					"Май","Июнь","Июль","Август","Сентябрь",
					"Октябрь","Ноябрь","Декабрь"

					];

	const renderCalendar = () => {
		let firstDayofMonth = new Date(currYear,currMonth, 0).getDay(),
		lastDateofMonth = new Date(currYear,currMonth + 1, 0).getDate(),
	  	lastDayoftMonth = new Date(currYear,currMonth, lastDateofMonth).getDay(),
		lastDateofLastMonth = new Date(currYear,currMonth, 0).getDate();
		let liTag = '';
		console.log(firstDayofMonth);
		for (let i = firstDayofMonth; i > 0; i--) {
			liTag += `<li class="inactive">${lastDateofLastMonth - i + 1}</li>`;
		}

		for (let i = 1; i <= lastDateofMonth; i++){
			let isToday = i === date.getDate() && currMonth === new Date().getMonth()
						&& currYear === new Date().getFullYear() ? "active" : "";
			liTag += `<li class="${isToday}">${i}</li>`;
		}

		for (let i = lastDayoftMonth; i < 7; i++) {
			liTag += `<li class="inactive">${i - lastDayoftMonth + 1}</li>`;
		}

		if(currentDate){
		currentDate.innerHTML = `${months[currMonth]}`;
		daysTag.innerHTML = liTag;
		}
	};

	renderCalendar();

	prevNextIcon.forEach(icon => {
		icon.addEventListener('click', () =>{
			currMonth = icon.id === "prev" ? currMonth - 1 : currMonth + 1;
			
			if(currMonth < 0 || currMonth > 11 ) {
				date = new Date(currYear,currMonth);
				currYear = date.getFullYear();
				currMonth = date.getMonth();
			} else {
				date = new Date();
			}
			renderCalendar();
		});
	});

	});

	// --- Page preloader ---
	const LayoutPagePreloader = (() => {

		class Core {

		    constructor(item) {
		        
		        this.item = item;
		        this.build();

		    }


		    build() {

		    	let preloaderStorage = localStorage.getItem('page-preloader');


		    	setTimeout(() => {
		    		
		    		this.item.classList.remove('page-preloader');	    		
		    		if (preloaderStorage !== 'on') localStorage.setItem('page-preloader', 'on');

		    	}, 1500);

		    }	

		}


		// init
		const init = () => new Core(document.documentElement);


		return { init };

	})();


	window.LayoutPagePreloader = LayoutPagePreloader;
	// --- /Page preloader ---

	console.info('main js');


	// init
	document.addEventListener('DOMContentLoaded', () => {

		// layouts
		LayoutPagePreloader.init();
		
	});

})();
